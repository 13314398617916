import React from 'react'
import { Box } from '@chakra-ui/react'
import './carousel.scss'


export const Carousel = () => {
    return (
      <div className='container-fluid p-0'>
        <div className='panSlider container-fluid p-0'>
        <div className='panSliderRow container-fluid'></div>
        </div>
      </div>
    )
}


import React from "react";
import ProjectHeader from "../../components/Header/ProjectHeader";

import {} from "@chakra-ui/react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Carousel } from "../../components/carousel/carousel";
import { col2,  mypx, xgap } from "../../constants/spacing";
import PCM from "../../components/cards/ProjectCardMotion";
import {
  Box,
  Grid,
  GridItem,
  SimpleGrid,
  Heading,
} from "@chakra-ui/react";
import Logo from "../../images/wilson/WA-Logo.gif";
import PCMStatic from "../../components/cards/PCMStatic";
import ReactVisibilitySensor from "react-visibility-sensor";
import { AnimatedWilson3 } from "../../components/animations/animations";
import SEO from "../../components/SEO";
import ProjectArrows, { ProjectLinkers } from "../../components/Header/ProjectArrows";
const Wilson = ({ data, location }) => {
  const dat = data.allWpProject.edges[0].node.hollandProjects;
  const DesktopVideo = dat.headerVideoDesktop.localFile.publicURL;
  const MobileVideo = dat.headerVideoMobile.localFile.publicURL;
  const preventClick = (e) => e.preventDefault()
  let image1;
  let image2;
  let image3;
  let image4;
  let image5;
  let image6;
  let image7;
  let image8;
  let image9;
  let image10;
  let image11;
  let image12;
  let image13;
  let image14;
  let image15;
  let image16;
  let image17;
  let image18;
  let image19;
  let image20;
  let image21;
  let image22;
  let image23;
  let image24;
  let image25;
  let image26;
  let image27;
  let image28;
  let image29;
  let image30;

  if (dat.image1) {
    image1 = getImage(dat.image1.localFile);
  }
  if (dat.image2) {
    image2 = getImage(dat.image2.localFile);
  }
  if (dat.image3) {
    image3 = getImage(dat.image3.localFile);
  }
  if (dat.image4) {
    image4 = getImage(dat.image4.localFile);
  }
  if (dat.image5) {
    image5 = getImage(dat.image5.localFile);
  }
  if (dat.image6) {
    image6 = getImage(dat.image6.localFile);
  }
  if (dat.image7) {
    image7 = getImage(dat.image7.localFile);
  }
  if (dat.image8) {
    image8 = getImage(dat.image8.localFile);
  }
  if (dat.image9) {
    image9 = getImage(dat.image9.localFile);
  }
  if (dat.image10) {
    image10 = getImage(dat.image10.localFile);
  }
  if (dat.image11) {
    image11 = getImage(dat.image11.localFile);
  }
  if (dat.image12) {
    image12 = getImage(dat.image12.localFile);
  }
  if (dat.image13) {
    image13 = getImage(dat.image13.localFile);
  }
  if (dat.image14) {
    image14 = getImage(dat.image14.localFile);
  }
  if (dat.image15) {
    image15 = getImage(dat.image15.localFile);
  }
  if (dat.image16) {
    image16 = getImage(dat.image16.localFile);
  }
  if (dat.image17) {
    image17 = getImage(dat.image17.localFile);
  }
  if (dat.image18) {
    image18 = getImage(dat.image18.localFile);
  }
  if (dat.image19) {
    image19 = getImage(dat.image19.localFile);
  }
  if (dat.image20) {
    image20 = getImage(dat.image20.localFile);
  }
  if (dat.image21) {
    image21 = getImage(dat.image21.localFile);
  }
  if (dat.image22) {
    image22 = getImage(dat.image22.localFile);
  }
  if (dat.image23) {
    image23 = getImage(dat.image23.localFile);
  }
  if (dat.image24) {
    image24 = getImage(dat.image24.localFile);
  }
  if (dat.image25) {
    image25 = getImage(dat.image25.localFile);
  }
  if (dat.image26) {
    image26 = getImage(dat.image26.localFile);
  }
  if (dat.image27) {
    image27 = getImage(dat.image27.localFile);
  }
  if (dat.image28) {
    image28 = getImage(dat.image28.localFile);
  }
  if (dat.image29) {
    image29 = getImage(dat.image29.localFile);
  }
  if (dat.image30) {
    image30 = getImage(dat.image30.localFile);
  }

  return (
    <>
    <SEO title={dat.projecttitle} description={dat.projectdescription} image={image1} url="https://hollandhames.com/project/wilson"/>
      <ProjectHeader
        title={dat.projecttitle}
        description={dat.projectdescription}
        desktopVid={DesktopVideo}
        mobileVid={MobileVideo}
        description={dat.projectdescription}
      />
     
      <SimpleGrid gap={xgap} px={mypx} pb={xgap} columns={1}>
        <Grid
          overflow='visible'
          gap={xgap}
          templateColumns={["(1, 1fr)", null, null, null, "(2, 1fr)"]}
          templateRows={["(4, 1fr)", null, null, null, "(7, 1fr)"]}>
          <GridItem
            colStart={1}
            colEnd={1}
            rowStart={1}
            rowEnd={[1, null, null, null, 3]}>
            <GatsbyImage onContextMenu={preventClick} image={image1} alt={dat.image1.altText} />
          </GridItem>
          <GridItem
            colStart={[1, null, null, null, 2]}
            colEnd={[1, null, null, null, 2]}
            rowStart={[2, null, null, null, 2]}
            rowEnd={[2, null, null, null, 4]}>
            <img onContextMenu={preventClick} src={Logo} />
          </GridItem>
          <GridItem
            colStart={1}
            colEnd={1}
            rowStart={[3, null, null, null, 3]}
            rowEnd={[3, null, null, null, 5]}>
            <PCM image={image2} alt={dat.image2.altText} />
          </GridItem>
          <GridItem
            colStart={[1, null, null, null, 2]}
            colEnd={[1, null, null, null, 2]}
            rowStart={[4, null, null, null, 4]}
            rowEnd={[4, null, null, null, 7]}>
            <PCM image={image3} alt={dat.image3.altText} />
          </GridItem>
        </Grid>
        <SimpleGrid justifyItems='center' columns={1} gap={xgap}>
          <PCM image={image4} alt={dat.image4.altText} />
        </SimpleGrid>
        <SimpleGrid columns={1} gap={xgap}>
          <PCMStatic>
            <Carousel />
          </PCMStatic>
        </SimpleGrid>
        <Grid
          overflow='visible'
          gap={xgap}
          templateColumns={["(1, 1fr)", null, null, null, "(2, 1fr)"]}
          templateRows={["(4, 1fr)", null, null, null, "(7, 1fr)"]}>
          <GridItem
            colStart={1}
            colEnd={1}
            rowStart={1}
            rowEnd={[1, null, null, null, 3]}>
            <PCM image={image6} alt={dat.image6.altText} />
          </GridItem>
          <GridItem
            colStart={[1, null, null, null, 2]}
            colEnd={[1, null, null, null, 2]}
            rowStart={[2, null, null, null, 2]}
            rowEnd={[2, null, null, null, 4]}>
            <PCM image={image7} alt={dat.image7.altText} />
          </GridItem>
          <GridItem
            colStart={1}
            colEnd={1}
            rowStart={[3, null, null, null, 3]}
            rowEnd={[3, null, null, null, 5]}>
            <PCM image={image8} alt={dat.image8.altText} />
          </GridItem>
          <GridItem
            colStart={[1, null, null, null, 2]}
            colEnd={[1, null, null, null, 2]}
            rowStart={[4, null, null, null, 4]}
            rowEnd={[4, null, null, null, 7]}>
            <PCM image={image9} alt={dat.image9.altText} />
          </GridItem>
        </Grid>
        <SimpleGrid columns={col2} justifyItems='center' gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={image12} alt={dat.image12.altText} />
          <Box textAlign={["left", null, null, null, "left"]}>
            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  RCP
                </Heading>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  FF&E
                </Heading>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  MEP
                </Heading>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  HVAC
                </Heading>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  GC
                </Heading>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  DD/CD
                </Heading>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  RENDERINGS
                </Heading>
              )}
            </ReactVisibilitySensor>

            <>
              <AnimatedWilson3 />
            </>

            <ReactVisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <Heading
                  className={isVisible ? "slideLeft enter" : "slideLeft"}
                  fontFamily='Rigid-Square'
                  fontSize={["24", "30", "32", "50", "70", "90"]}>
                  ELEVATIONS
                </Heading>
              )}
            </ReactVisibilitySensor>
          </Box>
        </SimpleGrid>
        <SimpleGrid gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={image13} alt={dat.image13.altText} />
        </SimpleGrid>
        <SimpleGrid
          justifyItems='center'
          alignItems='center'
          columns={col2}
          gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={image14} alt={dat.image14.altText} />
          <img onContextMenu={preventClick}
            src={dat.image15.localFile.publicURL}
            alt={dat.image15.altText}
          />
        </SimpleGrid>
        <SimpleGrid gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={image16} alt={dat.image16.altText} />
        </SimpleGrid>
      </SimpleGrid>
      <ProjectLinkers prevTitle='More Goodies' prevUrl='/project/more-goodies' nextTitle='VPA' nextUrl='/project/vpa' />
    </>
  );
};

export default Wilson;
export const query = graphql`
  {
    allWpProject(filter: { slug: { eq: "wilson-associates" } }) {
      edges {
        node {
          title
          hollandProjects {
            projecttitle
            projectdescription
            projectcategories
            image1 {
              altText
              localFile {
               
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image2 {
              altText
              localFile {
             
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image3 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image4 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image5 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image6 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image7 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image8 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image9 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image10 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image11 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image12 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    transformOptions: { rotate: 180 }
                  )
                }
              }
            }
            image13 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image14 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image15 {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image16 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image17 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image18 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image19 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image20 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image21 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image22 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image23 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image24 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image25 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image26 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image27 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image28 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image29 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image30 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            headerVideoDesktop {
              localFile {
                publicURL
              }
            }
            headerVideoMobile {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

import React, {useState, useEffect} from 'react'
import {Box, Heading} from '@chakra-ui/react'
import { animated, useTrail } from 'react-spring'
export function AnimatedWilson3() {

    const [finished, setFinished] = useState(false)
  
    const AnimatedBox = animated(Box)
  
  
  //* Initiating custom element (@chakra-ui "Heading")...
  
    const AnimatedHeading = animated(Heading)
  
    const items = ["F", "L", "O", "O", "R","P", "L", "A", "N", "S"];
    const trail = useTrail(items.length, {
      delay:100,
      config: {mass:20, tension:300, friction:150,},
      from: {  opacity: 0.01,textDecoration:'underline solid 2px', textDecorationColor:'transparent'  },
      to: { opacity:1, textDecorationColor:'gold'},
      loop: {reverse: true},
      
    });
  
    return (
      <animated.div style={{display: "flex" }}>
        {trail.map(({ ...style }, index) => (
        
          <AnimatedHeading
            key={index}
            fontFamily='Rigid-Square'
            fontSize={["24", "30", "32", "50", "70","90"]}
            style={{
              ...style,
              display: "flex",
              letterSpacing: "2px",
              textDecoration: "underline",
            }}
          >
            {items[index]}
          </AnimatedHeading>
        ))}
      </animated.div>
    );
  }
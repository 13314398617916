
import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import {Box} from '@chakra-ui/react'
import { GatsbyImage } from 'gatsby-plugin-image';
import './ProjectCardMotion.css'
const preventClick = (e) => e.preventDefault()
const AnimatedBox = animated(Box)
const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 50,
  (x - rect.left - rect.width / 2) / 50,
  1.1,
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export default function PCMStatic({
  w = 3600,
  h = 2400,
  alt="",
  image,
  children
}) {

  const ref = useRef(null);
  const [xys, set] = useState([0, 0, 1]);
  const config = {
    mass: 1,
    tension: 170,
    friction: 26,
    clamp: true,
    precision: 0.01,
    velocity: 0,
    easing: (t) => t,
  };
  const props = useSpring({ xys, config });


  const AnimatedBox = animated(Box)
  const AnimatedImage = animated(GatsbyImage)

  

  return (
  
    <Box onContextMenu={preventClick} style={{cursor:'grab'}}ref={ref}>
      
      <AnimatedBox
        className='ccard portrait ofv'
        style={{ color: "white", overflow:'visible', transform: props.xys.to(trans)}}
        onMouseLeave={() => set([0, 0, 1])}
        onMouseMove={(e) => {
          const rect = ref.current.getBoundingClientRect();
          set(calc(e.clientX, e.clientY, rect));
        }}>
          
        {children}
       
      </AnimatedBox>
    </Box>
  
  );
}
